.influeProfileContainer {
  padding: 16px;

  @media (min-width: 768px) {
    padding: 30px;
  }

  @media (min-width: 1280px) {
    padding: 50px;
  }
}

.growthFilter {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.campignBreadcrumbs {
  composes: campignBreadcrumbs from './campaign.module.scss';
}

.navTxt {
  composes: navTxt from './campaign.module.scss';
}

.rightArrow {
  flex-shrink: 0;
  composes: rightArrow from './campaign.module.scss';
}

.navSecndTxt {
  composes: navSecndTxt from './campaign.module.scss';
}

.profileLeftContainer {
  width: 308px;
  flex-shrink: 0;

  @media (max-width: 992px) {
    max-width: 100%;
    width: 100%;
  }
}

.profileRightSummary {
  width: calc(100% - 358px);

  @media (max-width: 992px) {
    width: 100%;
  }
}

.heading {
  color: #19191c;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 23px;
}

.innrProfileAnalysis {
  display: flex;
  gap: 50px;
  margin-top: 23px;
  width: 100%;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.navListWrapper {
  height: 38px;
}

.influencerBriefContent {
  box-shadow: 0px 2px 10px #ff00a24d;
  border: 1px solid #ffffff;
  border-radius: 1px;
  width: 100%;
}

.infInnerBrief {
  padding: 18px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
}

.ellipseImg {
  border: 3px solid #ff00a2;
  border-radius: 100px;
  width: 139px;
  height: 139px;
  object-fit: cover;
}
.navTabmain {
  display: flex;
  justify-content: space-between;
}
.customTabs {
  .MuiTabs-indicator {
    background-color: #d633db !important;
  }
}
.customTab {
  color: #9E9E9E;
  font-weight: 500 !important;
  text-transform: capitalize;
  transition: color 0.3s ease;
  border-bottom: 1px solid #efefef;

  &:hover {
    color: #d633db;
  }
}
.customTabActive {
  color: #d633db !important;
  font-weight: 600 !important;
  position: relative;
}

.customTabActive::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #d633db;
}
.MuiTabs-indicator {
  background-color: transparent !important;
}
.subBlock1 {
  composes: subBlock1 from './search.module.scss';
}

.scoreTxt {
  composes: scoreTxt from './search.module.scss';
}

.subInnrBlock {
  composes: subInnrBlock from './search.module.scss';
}

.spanTxt {
  composes: spanTxt from './search.module.scss';
}

.profileBrief {
  composes: profileBrief from './search.module.scss';
}

.userLocation {
  composes: userLocation from './search.module.scss';
}

.locTxt {
  composes: locTxt from './search.module.scss';
}

.userSocialPlaylist {
  composes: userSocialPlaylist from './search.module.scss';
}

.socialImg {
  composes: socialImg from './search.module.scss';
}

.profileImg {
  position: relative;
}

.infName {
  color: #1f2933;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 22px;
  text-align: center;
}

.passionTxt {
  font-size: 0.875rem;
  color: #52606d;
  font-weight: 400;
  line-height: 1.4;
}

.profileCategory {
  composes: profileCategory from './search.module.scss';
  justify-content: center;
  width: 100%;
}

.categoryTxt {
  composes: categoryTxt from './search.module.scss';
  overflow: hidden;
  text-overflow: ellipsis;
}

.addCampaignButton {
  white-space: nowrap;
  padding: 11px 10px;
  gap: 8px;
  width: 100%;
  justify-content: center;
  composes: addCampaignButton from './search.module.scss';
}

.profileBlock3 {
  composes: profileBlock3 from './search.module.scss';
  flex-shrink: 0;
}

.addBtn {
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
}

.connectBtns {
  width: 100%;
  display: flex;
  gap: 7px;
  opacity: 0.5;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
}

.commonBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #19191c;
  padding: 11px 23px;
  width: 100%;
  justify-content: center;
  color: #19191c;
  font-size: 0.75rem;
  font-weight: 600;
}

.summaryTabList {
  background: #fff8fd;
}

.summaryTabListItem {
  display: flex;
  justify-content: space-between;
  padding: 14px 16px;
  align-items: center;
}

.activeTabItem {
  border: 1px solid #ffbfe8;
  background: #fee9f6;
}

.subList {
  display: flex;
  gap: 16px;
  align-items: center;
  width: calc(100% - 36px);
}

.activeTxt {
  font-size: 1rem;
  color: #851f94;
  font-weight: 600;
  line-height: 1.4;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabTxt {
  color: #616e7c;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  cursor: pointer;
}

.navList {
  display: flex;
  gap: 25px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
  overflow: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.navListItem {
  display: flex;
  gap: 10px;
  color: #616e7c;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 400;
  cursor: pointer;
  flex-shrink: 0;
}

.activeNavHeading {
  font-weight: 600;
  color: #d633db;
}

.navContent {
  margin-top: 23px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    // padding: 0px;
  }
}

.socialProfileMetricsCard {
  padding: 27px 25px;
  // max-width: 440px;
  width: 100%;
  position: relative;
}

.profileOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 10;
  backdrop-filter: blur(8px);
  background-color: transparent;
  padding: 16px;
  text-wrap: balance;
}

.instaMetricsCard {
  border: 1px solid #8f64ce4d;
  background: #7f4cc70a;
}

.youtubeMetricsCard {
  border: 1px solid #f287874d;
  background: transparent linear-gradient(99deg, #f2878708 0%, #ff000008 100%);
}

.twitterMetricsCard {
  border: 1px solid rgb(0 0 0 / 50%);
  background: transparent
    linear-gradient(99deg, rgb(0 0 0 / 3%) 0%, rgb(0 0 0 / 3%) 57%);
}

.facebookMetricsCard {
  border: 1px solid #52b6fe66;
  background: transparent linear-gradient(99deg, #52b6fe08 2%, #1877f208 70%);
}

.metricsCardTop {
  display: flex;
  justify-content: space-between;
}

.platformHandle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.platformHandleName {
  color: #616e7c;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 400;
  cursor: pointer;
}

.platformScore {
  display: flex;
  gap: 10px;
  align-items: center;
}

.platformScoreTxt {
  color: #b90cbe;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.4;
}

.metricsCardBottom {
  margin-top: 25px;
}

.platformMetrics {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  @media (min-width: 1280px) {
    gap: 40px;
  }
  @media (min-width: 1440px) {
    gap: 60px;
  }
}

.platformMetricsItems {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mtricsLabel {
  color: #616e7c;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
  display: flex;
  gap: 5px;
  align-items: center;
}

.mtricsLabel img {
  cursor: pointer;
}

.metricsValue {
  color: #19191c;
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: 600;
}

.navCategory {
  margin-top: 21px;
}

.sectionHeader {
  display: flex;
  gap: 15px;
  align-items: center;
}

.contntTxt {
  color: #24263a;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.4;
}

.sectionBody {
  margin-top: 15px;
}

.sectioncategoryHeader {
  display: flex;
  gap: 10px;
}

.contcatTxt {
  color: #616e7c;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
}

.sectioncategoryHeader img {
  cursor: pointer;
}

.contentBarHighChart {
  margin-top: 25px;
}

.CustomStackedBarWrapper {
  height: 40px;
  width: 100%;
  border-radius: 8px;
  display: flex;
}

.category {
  height: 100%;
  flex-shrink: 0;
}

.category:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.category:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.profileInterestList {
  margin-top: 26px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  row-gap: 28px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}

.interstColumn {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.customBar {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.dataBrief {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

p.dataLabel {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #3e4c59;
  text-transform: capitalize;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
}

p.dataShare {
  color: #3e4c59;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
}

.progressB {
  height: 8px;
  background: #e9ecef;
  display: flex;
}

span.progressBar {
  border-radius: 2px;
  background: #2d59f5;
  // width: 90%;
}

.brandPartnerTypeTab {
  margin-top: 25px;
  // width: 100%;
  // max-width: 885px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.brandTypeList {
  display: flex;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  white-space: nowrap;
  overflow: auto hidden;
  width: 100%;
  // max-width: 1332px;
}

.brandSubList {
  padding-bottom: 6px;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
  color: #7b8794;
  cursor: pointer;
}

.partnerList {
  margin-top: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: auto hidden;
  width: 100%;
  padding-bottom: 8px;
}

.brandCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 188px;
  height: 240px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-shrink: 0;
  padding: 24px 16px 16px;
  border: 1px solid #e4e7eb;

  & .brandLogo {
    width: 84px;
    height: 84px;
    object-fit: contain;
  }
}

.cardBody {
  text-align: center;
}

.patnrName {
  color: #1f2933;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: capitalize;
}

.patnrHandle {
  font-size: 0.875rem;
  line-height: 1.6;
  font-weight: 400;
  color: #7b8794;
}

.patnrCollabPostCount {
  cursor: pointer;
  color: #7b8794;
  text-decoration: underline;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 400;
}

// ===================================instagram profile screen
.navContentSection {
  margin-top: 21px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.tabCardSection {
  border: 1px solid #f1f4f8;
  width: 268px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.sectionHeader {
  display: flex;
  gap: 15px;
}

.followerLabel {
  color: #616e7c;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
}

.sectionBottom {
  display: flex;
  gap: 22px;
  align-items: center;
  margin-top: 10px;
}

.followerCount {
  color: #1f2933;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.4;
}

.radioCount {
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.4;
  color: #ff3dec;
}

.offerTxt {
  background: #c543b4;
  padding: 3px 8px;
  color: #ffffff;
  font-size: 0.688rem;
  font-weight: 500;
}

.innerDiv {
  border: 1px solid #e6e6e6;
  padding: 25px;
  gap: 50px;
  composes: innerDiv from './campaign.module.scss';

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.engamntBody {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.timelineTxt {
  composes: timelineTxt from './campaign.module.scss';
}

.campaignList {
  composes: campaignList from './campaign.module.scss';
  align-items: center;
}

.campaignInnrList {
  composes: campaignInnrList from './campaign.module.scss';
}

.headingTxt {
  composes: headingTxt from './campaign.module.scss';
}

.detailInnrTxt {
  composes: detailInnrTxt from './campaign.module.scss';
}

.commentRatioContainer {
  border-bottom: 1px solid #d7b2d880;
  padding-bottom: 26px;
}

.ratioTxt {
  color: #1f2933;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.4;
}

.commntTxt {
  color: #616e7c;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
}

.performanceAverageBrief {
  display: flex;
  gap: 12px;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 25px;
}

.datasetPill {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #e4e7eb;
  padding: 8px 12px;
  border-radius: 24px;
}

.creatorDashedIcon {
  width: 26px;
  border-top: 2px dashed #fb6eff;
}
.categoryDashIcon {
  width: 26px;
  border-top: 2px dashed #7c287d;
}

.datasetInfo {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 13px;
  text-transform: capitalize;
}

.datasetName {
  color: #1f2933;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 400;
}

.datasetValue {
  color: #1f2933;
  font-size: 0.813rem;
  font-weight: 600;
  line-height: 1.4;
}

.benchmarkGraph img {
  width: 100%;
}

.overviewSection {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
}

.overviewGrid {
  border: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.stateBottom {
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 14px;
}

.postHeader {
  composes: postHeader from './campaign.module.scss';
}

.insightHeading {
  composes: insightHeading from './campaign.module.scss';
}

.TimeBox {
  composes: TimeBox from './campaign.module.scss';
}

.yearList {
  composes: yearList from './campaign.module.scss';
}

.activeYearList {
  composes: activeYearList from './campaign.module.scss';
}

.audienceDemography {
  display: flex;
  border: 1px solid #e6e6e6;
  margin-top: 50px;
}

.audienceDemographywrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 100%;
  flex-grow: 1;
  border-right: 1px solid #e6e6e6;
  gap: 24px;
}

.growthSection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  & .growthWrapper {
    width: 100%;
  }

  @media (min-width: 768px) {
    flex-wrap: nowrap;

    & .growthWrapper {
      max-width: 356px;
    }
  }
}

.hashtagsList {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.hastagItems {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #f1f4f8;
}

p.hastagText {
  color: #3e4c59;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}

.postWrapper {
  composes: postWrapper from './search.module.scss';
  overflow: scroll hidden;
  flex-wrap: nowrap;
  width: 100%;
  gap: 12px;
}

.postBlock {
  composes: postBlock from './search.module.scss';
  width: 100%;
  max-width: 204px;
  flex-shrink: 0;
  padding: 0 0 16px;
  cursor: pointer;
}

.propicImg {
  composes: propicImg from './search.module.scss';
}

.contentTextWrapper {
  composes: contentTextWrapper from './search.module.scss';
}

.overlayIcon {
  composes: overlayIcon from './search.module.scss';
  top: 10px;
  right: 10px;
}

.bottomBlock {
  composes: bottomBlock from './search.module.scss';
}

.viewsColumn {
  composes: viewsColumn from './search.module.scss';
}

.viewTxt {
  composes: viewTxt from './search.module.scss';
}

.monthTxt {
  composes: monthTxt from './search.module.scss';
}

.contntDiv {
  display: flex;
  align-items: center;
  gap: 25px;
}

.allTxt {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  color: #cd3395;
  cursor: pointer;
}

.enganmntViewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
}

.colorCate {
  border-radius: 8px;
  width: 16px;
  height: 16px;
  background: #e289f2;
  display: flex;
}
