@use 'assets/scss/variables' as v;

//=================================== sidebar css
.sidebar {
  composes: sidebar from './dashboard.module.scss';
}

.navImg {
  composes: navImg from './dashboard.module.scss';
}

.navList {
  composes: navList from './dashboard.module.scss';
}

.hoverState {
  composes: hoverState from './dashboard.module.scss';
}

.navLinks {
  composes: navLinks from './dashboard.module.scss';
}

.homeIcon {
  composes: homeIcon from './dashboard.module.scss';
}

.navImg {
  composes: navImg from './dashboard.module.scss';
}

.navbarBtm {
  composes: navbarBtm from './dashboard.module.scss';
}

//=================================== sidebar css

// =================================page Container css
.pageContainer {
  composes: pageContainer from './dashboard.module.scss';
}

.dashboardTop {
  composes: dashboardTop from './dashboard.module.scss';
}

.transBg {
  composes: transBg from './dashboard.module.scss';
}

.searchBox {
  z-index: 1;
  padding: 44px 50px;

  @media (max-width: 992px) {
    padding: 20px 15px;
  }
}

.inputSearch {
  position: relative;
}

.search {
  width: 100%;
  padding: 16px 16px 16px 55px;
  font-size: 1.125rem;
  // color: #bbbbbb;
  line-height: 1.4;
  font-weight: 400;
  outline: none;

  @media (max-width: 992px) {
    padding: 15px 50px;
    font-size: 0.875rem;
  }
}

.searchImg {
  position: absolute;
  top: 16px;
  padding-left: 16px;

  @media (max-width: 992px) {
    top: 12px;
  }
}

.categoryButtons {
  padding: 0px 26px;
  display: flex;
  gap: 5px;
  z-index: 1;

  @media (max-width: 992px) {
    flex-wrap: wrap;
    padding: 0 20px;
  }
}

.influencerCateBtn {
  background: rgba(255, 255, 255, 0.2509803922);
  padding: 15px 30px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: #19191c;
  min-width: 226px;
  cursor: pointer;

  @media (max-width: 992px) {
    padding: 15px 8px;
    font-size: 0.875rem;
  }

  @media (max-width: 641px) {
    flex-grow: 1;
  }
}

.whiteActiveBtn {
  background: v.$white;
}

.influencerHeader {
  z-index: 1;
  position: relative;
  padding: 29px 50px;

  @media (max-width: 992px) {
    padding: 16px;
  }
}

.headingList {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inflencrTxt {
  font-size: 1.5rem;
  color: #19191c;
  font-weight: 600;
  line-height: 2;
}

.categorySelector {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 16px;
  border-bottom: 1px solid #cbcdd0;
  padding-bottom: 40px;

  @media (max-width: 992px) {
    gap: 16px;
    margin-top: 12px;
    padding-bottom: 20px;
    flex-wrap: nowrap;
    overflow: auto;
    // to keep shadow
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -10px;
    margin-right: -10px;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.influencerCardList {
  display: flex;
  gap: 24px;
  padding: 50px;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 17px;
  }
}

.influencerCardSection1 {
  width: 100%;
  max-width: 400px;

  @media (max-width: 1200px) {
    max-width: 100%;
  }
}

.influencerCardSection2 {
  width: 100%;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.trendingTxt {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.4;
  color: #19191c;
}

.influencerSelectors {
  padding: 0px 50px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    padding: 0 10px;
    gap: 8px;
  }
}

.categryDropdown {
  border-radius: 50px;
  border: 2px solid #ff00a280;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  @media (max-width: 992px) {
    padding: 4px 12px;
    gap: 8px;
  }
}

.activeDropdown {
  background: #ff00a2;
}

.dropdownItem {
  display: flex;
  align-items: center;
  gap: 9px;
}

.itemTxt {
  font-size: 1rem;
  color: #1f2933;
  font-weight: 400;

  @media (max-width: 992px) {
    font-size: 0.875rem;
  }
}

.itemImage {
  object-fit: contain;
  height: 24px;
  width: auto;
}

.activeTxt {
  color: v.$white;
}

.influencerProfileContainer {
  margin: 25px 0px;
}

.influencerProfile {
  display: flex;
  box-shadow: 0px 2px 10px #ff00a24d;
  border: 1px solid #ffffff;
  border-radius: 1px;
  // max-width: 376px;
  width: 100%;
  margin-bottom: 24px;
  position: relative;
}

.transBg {
  composes: transBg from './dashboard.module.scss';
}

.cardtransBg {
  composes: cardtransBg from './dashboard.module.scss';
}

.influencrProfilePhoto {
  height: 100%;
  max-width: 114px;
  object-fit: cover;
}

.influencrDetail {
  width: 100%;
  padding: 8px 16px;
}

.profileContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.profileTop {
  display: flex;
  gap: 10px;
}

.profileName {
  font-size: 1.063rem;
  line-height: 1.4;
  color: #14171f;
  font-weight: 600;
}

.profileHandle {
  color: #818181;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
}

.profileActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 9px;
}

h3.scoreTxt {
  color: #718096;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 400;
}

.scoreCount {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.score {
  font-size: 1rem;
  color: #14171f;
  font-weight: 600;
  line-height: 1.4;
}

.scorePercent {
  border-radius: 20px;
  background: #8db499;
  padding: 3px 10px;
  font-size: 0.75rem;
  color: #ffffff;
  font-weight: 600;
}

.influencerProfileList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
}

.moreProfileCount {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 1.4;
  color: #1f2933;
  background: #ffff;
  transform: scale(1.2);
  border: 1px solid #fff;
  border-radius: 100px;
  flex-shrink: 0;
}

.subCategoryTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
  height: 52px;

  // margin-top: -25px;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    gap: 10px;
    height: auto;
  }
}

.searchField {
  position: relative;
}

.sIcon {
  position: absolute;
  left: 12px;
  top: 12px;

  @media (max-width: 992px) {
    left: 8px;
    top: 12px;
  }
}

.searchTxt {
  background: #f8f9fa;
  padding: 14px 14px 14px 46px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  color: #19191c;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 500;
  outline: none;
  width: 100%;
  max-width: 281px;

  @media (max-width: 992px) {
    padding: 14px 14px 14px 38px;
  }
}

.platformTxt {
  color: #1f2933;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 600;
  width: 236px;
  overflow-wrap: anywhere;
  text-transform: capitalize;
}

.viewAllBtn {
  display: flex;
  gap: 10px;
  font-size: 0.875rem;
  line-height: 1.4;
  color: #ff00a2;
  font-weight: 600;
  border: none;
  outline: none;
  white-space: nowrap;
}

.rightArrow {
  transform: rotate(270deg);
  cursor: pointer;
}

.downloadedTxt {
  composes: downloadedTxt from './profile-dashboard.module.scss';
}

.catgryDiv {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.catgrySpan {
  background: #ffffff;
  box-shadow: 0px 2px 10px #0000001a;
  border: 1px solid #00000000;
  border-radius: 6px;
  padding: 4px 10px;
  color: #ff00a2;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
  width: max-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.catgryTxt {
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
  cursor: pointer;
}

.moreContainer {
  background-color: white;
  font-size: 0.875rem;
  color: #ffffff;
  padding: 8px;
  background-color: #402061;
  border-radius: 5px;
}

.profileDiv {
  display: flex;
  align-items: center;
  gap: 10px;
}

.customListDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addList {
  background: transparent linear-gradient(101deg, #7c087f 0%, #a506aa 100%);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px 20px;
  font-size: 0.75rem;
  line-height: 1.4;
  color: #fff;
  font-weight: 600;
}
.addInfluencer {
  background: transparent linear-gradient(101deg, #7c087f 0%, #a506aa 100%);
  align-items: center;
  gap: 10px;
  padding: 14px 20px;
  font-size: 0.75rem;
  line-height: 1.4;
  color: #fff;
  font-weight: 600;
}

.guestUser {
  composes: guestUser from './dashboard.module.scss';
}

.subscriptionBox {
  composes: subscriptionBox from './dashboard.module.scss';
  padding: 14px 26px;
  bottom: 10px;

  @media (max-width: 992px) {
    padding: 12px 16px;
    position: relative;
    margin: 0 16px;
    max-width: calc(100% - 32px);
  }
}

.selectBoxContrller {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
}

.counter {
  display: flex;
  align-items: center;
  gap: 15px;
}

.count {
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.4;
}

.countTxt {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
}

.influncrControl {
  display: flex;
  align-items: center;
  gap: 14px;

  @media (max-width: 1280px) {
    flex-wrap: wrap;
  }

  @media (max-width: 640px) {
    & > button {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
}

.deselectBtn {
  border: 1px solid #ffffff;
  padding: 14px 48px;
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 400;
}

.cntrllerBtns {
  display: flex;
  gap: 10px;
  padding: 17px 35px;
  background: transparent linear-gradient(103deg, #7c087f 0%, #a506aa 100%);
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 400;
  color: #ffffff;
  white-space: nowrap;
}

.compareCntrlrBtns {
  background: transparent linear-gradient(106deg, #b40173 0%, #ff00a2 100%);
}

.checkBox {
  color: yellow;
}

// ======================================== profile influencer popup screen ======================

.popupProfileDetail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
  align-items: center;
  padding: 0px 25px;
}

.profileBlock {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.userName {
  font-size: 0.938rem;
  line-height: 1.4;
  color: #14171f;
  font-weight: 600;
}

.userHandle {
  font-size: 0.75rem;
  line-height: 1.4;
  color: #818181;
  font-weight: 400;
}

.costTable {
  padding-bottom: 25px;
}

.costContainer {
  width: 100%;
}

.costContainer th {
  color: #19191c;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.4;
  padding-bottom: 15px;
  padding-left: 25px;

  &:last-child {
    padding-right: 25px;
  }
}

.costContainer td {
  padding: 15px 25px;

  &:last-child {
    padding-right: 25px;
  }
}

.costContainer tr:nth-child(even) {
  background: rgba(255, 0, 162, 0.04);
}

.socialList {
  color: #8c888c;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4;
}

.socialCost {
  color: #19191c;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4;
  text-align: right;
}

.radiocityRating {
  display: flex;
  justify-content: flex-end;
  gap: 7px;
  align-items: center;
}

.ratingTxt {
  font-size: 0.75rem;
  color: #a90aad;
  font-weight: 600;
  line-height: 1.4;
}

.locationContainer {
  display: flex;
  gap: 5px;
  align-items: center;
}

.countryTxt {
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 400;
  color: #52606d;
}

// =====================profile drawer popup screen==============================

.profileDrawer {
  display: flex;
  gap: 30px;
  padding: 20px 30px;

  @media (max-width: 1280px) {
    flex-wrap: wrap;
  }

  @media (max-width: 640px) {
    padding: 20px 16px;
  }
}

.profileAnalysis {
  width: 456px;
  flex-shrink: 0;
  @media (max-width: 1280px) {
    width: 100%;
  }
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}

.p1Block {
  display: flex;
  gap: 20px;
}

.profileOuterDiv {
  border: 3px solid #ff00a2;
  border-radius: 100px;
  width: 80px;
  height: 80px;
}

.profilePicture {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.subBlock1 {
  box-shadow: 0px 3px 6px #fc9fff59;
  border-radius: 16px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 0px;
  margin-top: -17px;
  position: absolute;
  max-width: 74px;
  width: 100%;
}

.scoreTxt {
  color: #900794;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.5;
}

.subInnrBlock {
  display: flex;
  gap: 4px;
  align-items: center;
}

.spanTxt {
  color: #a90aad;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.5;
  margin-top: 4px;
}

.profileBrief {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  flex-wrap: wrap;

  & > .influencerName:first-child {
    margin-right: 10px;
  }
}

.influencerName {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1f2933;
  line-height: 1.4;
  flex-shrink: 0;

  @media (max-width: 640px) {
    font-size: 1rem;
  }
}

.influencerHandle {
  color: #7b8794;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.4;
  text-decoration: underline;
}

.userLocation {
  display: flex;
  gap: 6px;
  margin-top: 8px;
}

.locTxt {
  font-size: 1rem;
  color: #52606d;
  font-weight: 400;
  line-height: 1.4;
}

.userSocialPlaylist {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

.socialImg {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.profileBlock3 {
  background: transparent linear-gradient(133deg, #e00a81 0%, #a7005d 100%);
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.profileCategory {
  margin-top: 26px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 640px) {
    margin-top: 16px;
  }
}

.categoryTxt {
  box-shadow: 0px 2px 10px #0000001a;
  border: 1px solid #00000000;
  border-radius: 6px;
  color: #ff00a2;
  font-size: 0.875rem;
  padding: 4px 15px;
}

.profileActionButton {
  margin-top: 31px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  @media (max-width: 640px) {
    margin-top: 16px;
    & > div {
      width: 100%;
    }
  }

  .actionsLinks {
    display: flex;
    gap: 10px;

    @media (max-width: 640px) {
      & > * {
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.addCampaignButton {
  background: transparent linear-gradient(103deg, #7c087f 0%, #a506aa 100%);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 15px;
  font-size: 0.75rem;
  color: #ffffff;
  font-weight: 600;

  @media (max-width: 640px) {
    width: 100%;
    justify-content: center;
  }
}

.analyseProfileButton {
  background: transparent linear-gradient(106deg, #d633db 0%, #a600ac 100%);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 13px 15px;
  font-size: 0.75rem;
  color: #ffffff;
  font-weight: 600;
}

.viewCostButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 13px 17px;
  color: #19191c;
  font-size: 0.75rem;
  font-weight: 600;
  border: 1px solid #19191c;
  opacity: 0.5;
}

.platformMetrics {
  box-shadow: inset 6px 8px 17px #fac2fc40, 0px 16px 60px #c3c3c333;
  border-radius: 1px;
  background: #ff00a20a;
  margin-top: 25px;
  padding: 25px 31px;
  max-width: 447px;
  width: 100%;

  @media (max-width: 1280px) {
    max-width: none;
  }

  @media (max-width: 640px) {
    padding: 16px;
    margin-top: 16px;
  }
}

.metricsList {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.metricsHeding {
  color: #19191c;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.4;
}

.metricLabel {
  font-size: 0.875rem;
  color: #8c878b;
  line-height: 1.4;
  font-weight: 600;
}

.postAnalysis {
  width: 100%;
  flex-shrink: 1;

  & .postWrapper {
    @media (max-width: 1280px) {
      max-height: none;
    }

    @media (max-width: 640px) {
      justify-content: center;
    }
  }
}

.tabsWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  & > .cross {
    @media (max-width: 1280px) {
      display: none;
    }
  }
}

.tab {
  display: flex;
  gap: 15px;
  border-bottom: 1px solid #d7b2d8;

  @media (max-width: 640px) {
    gap: 8px;
  }
}

.tabInnr {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 5px;
  width: 159px;
  justify-content: center;
  cursor: pointer;
  border-bottom: 3px solid transparent;

  & > * {
    flex-shrink: 0;
  }

  @media (max-width: 640px) {
    width: 130px;
  }
}

.activeTab {
  border-color: #d633db;

  & .postTxt {
    color: #d633db;
    font-weight: 600;
  }
}

.postTxt {
  color: #7b8794;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
}

.postWrapper {
  margin-top: 22px;
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
  max-height: 516px;
  overflow-y: auto;
}

.postBlock {
  position: relative;
  width: 100%;
  max-width: 205px;
}

.propicImg {
  width: 204px;
  height: 204px;
  object-fit: cover;
  background-color: #e4e7eb;
  border-width: 0;
}

.contentTextWrapper {
  width: 204px;
  height: 204px;
  overflow: auto;
  background-color: #e4e7eb;
  padding: 16px;

  & > p {
    color: #616e7c;
  }
}

.overlayIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.bottomBlock {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.viewsColumn {
  display: flex;
  gap: 5px;
}

.viewTxt {
  font-size: 0.75rem;
  color: #616e7c;
  line-height: 1.4;
  font-weight: 400;
  margin-right: 8px;
}

.monthTxt {
  color: #7b8794;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.4;
}

// ========================== search popup container screen

.searchPopupContainer {
  box-shadow: 0px 2px 10px #ff00a24d;
  border: 1px solid #ffffff80;
  background: #ffffff;
  padding: 50px;
}

.searchPopup {
  display: flex;
  gap: 158px;
}

.searchHeading {
  font-size: 1rem;
  color: #19191c;
  line-height: 1.4;
  font-weight: 600;
  padding-bottom: 25px;
}

.searchSubList {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 25px;
  cursor: pointer;
}

.imgWidth {
  width: 25px;
  height: 23px;
  object-fit: contain;
}

.searchsubTxt {
  color: #1f2933;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  cursor: pointer;
  text-transform: capitalize;
}

.searchSecList {
  padding-bottom: 15px;
}

.searchSecList p {
  font-size: 0.938rem;
  color: #14171f;
}

.customSearch {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.customBoxWrapper {
  display: grid;
  gap: 48px;
  padding: 10px 50px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

  @media (max-width: 992px) {
    gap: 16px;
    padding: 0 16px 10px;
  }

  @media (max-width: 567px) {
    grid-template-columns: 1fr;
  }
}

.customBox {
  box-shadow: 0px 2px 10px #ff00a24d;
  border: 1px solid #ffffff;
  border-radius: 1px;
  width: 100%;
  max-width: 510px;
  flex-shrink: 0;
  padding: 20px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.customHeader {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-bottom: 1px solid #70707036;
  padding-bottom: 12px;
}

.customImgs {
  display: flex;
  gap: 8px;

  @media (max-width: 500px) {
    display: none;
  }
}

.customBody {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.iconWidth {
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.brandHeading {
  font-size: 1.125rem;
  line-height: 1.4;
  color: #14171f;
  font-weight: 600;
  max-width: 230px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.brandDetail {
  color: #818181;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.4;
}

.brandDetail span {
  font-size: 0.875rem;
  font-weight: 500;
}

.custmInnrImg {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  & > * {
    flex-shrink: 0;
  }
}

// ==========================custom popup

.CustmPopupWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputWrapper {
  border: 1px solid #e6e6e6;
  background: #f8f9fa;
  padding: 15px;
}

.custmIn {
  outline: none;
  color: #19191c;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 500;
  background: #f8f9fa;
}

.error {
  composes: error from '../common.module.scss';
}

.campignBreadcrumbs {
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.navTxt {
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
  color: #7c287d;
  cursor: pointer;
}

.navSecndTxt {
  color: #cd3395;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
}

.brTxt {
  color: #24263a;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.4;
}

.brandHeader {
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 20px 0px;
}

// ========= Add Influencer Dialogue========================
.campaignSearchField {
  width: 100%;
}

.campignSearchIcon {
  left: 12px;
}

.selectInfFooter {
  padding: 0 25px;
  margin: 25px 0px;
}

.infCheckBox {
  composes: selectInfFooter;
  overflow-y: auto;
  height: 395px;
  max-height: calc(100vh - 351px);
}

.innrBoxCnt {
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 25px;
  }
}

.subCnt {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cntntTitle {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4;
  color: #14171f;
}

.crBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 26px;
}

.prevBtn {
  color: #757575;
  font-size: 0.875rem;
  font-weight: 500;
}

.updateBtn {
  width: 100%;
  background: transparent linear-gradient(101deg, #7c087f 0%, #a506aa 100%);
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 15px 0px;
}

// influencer posts dialog

.postsContainer {
  max-height: calc(100vh - 188px);
  overflow: auto;
  margin-bottom: 28px;
}

.postsWrapper {
  padding: 0px 25px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(204px, 1fr));
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.centerPost {
  display: flex;
  justify-content: center;
}

.rateInputWrapper {
  width: 100px;
  position: relative;

  &::after {
    font-family: Arial, Helvetica, sans-serif;
    content: '₹';
    position: absolute;
    left: 12px;
    top: 10px;
  }
}

.rateInput {
  align-items: right;
  border: 1px solid #ff00a2;
  border-radius: 4px;
  padding: 8px 8px 8px 30px;
  width: 100px;

  @media (min-width: 768px) {
    width: 120px;
  }
}
